.login-wrapper {
  background: rgba(255, 255, 255, 0.8);
  width: 400px;
  margin: 15% auto;
  border-radius: 20px;
}


.login-error {
  color: red
}

.flex-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.login-input {
  border-radius: 15px !important;
}


.form-signin {
  max-width: 330px;
  padding: 10% 15px;
  margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400 !important;
}

.btn-login {
  background-color: black;
  color: white;
  border-radius: 20px;
  border: 0px;
  width: 100%;
  padding: 10px;
}


.logo-img {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo-img img {
  max-width: 150px !important;
}

h2 {
  margin-top: 10px !important;
}

h4 {
  margin-top: 10px !important;
}


label {
  font-weight: 400 !important;
}